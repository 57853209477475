import axios from 'axios';
import { acquireLock, releaseLock } from "../algorithm";
import { idb_get, idb_set } from "../state/idb";
const serverUrl = 'https://api.ideocanvas.workers.dev'
const key = "shorturl";
export const generateShortUrl = async (url) => {
  const res = await axios.post(`${serverUrl}/generateShortUrl`, {url});
  console.log('res', res);
  if (res.data?.shorturl) {
    await addShortUrlHistory(res.data.shorturl);
  }
  return;
}

export const getUrlByShortUrl = async (short) => {
  console.log("getUrlByShortUrl", short);
  const res = await axios.get(`${serverUrl}/getShortUrl/${short}`);
  console.log('res', res);
  return res?.data?.shorturl?.url;
}

export const getShortUrlHistory = async () => {
  const list = await idb_get(key);
  return list;
}

export const removeShortUrlHistory = async (item) => {
  const releaseKey = await acquireLock(key, 5000);
  const list = (await idb_get(key)) || [];
  const newList = list.filter(l => {
    if (l.url === item.url) {
      return false;
    } else {
      return true;
    }
  })
  await idb_set(key, newList);
  releaseLock(key, releaseKey);
  return;
}

export const addShortUrlHistory = async (item) => {
  console.log("addShortUrlHistory", item);
  const releaseKey = await acquireLock(key, 5000);
  console.log("addShortUrlHistory lock", releaseKey);
  const list = (await idb_get(key)) || [];
  const newList = list.filter(l => {
    if (l.url === item.url) {
      return false;
    } else {
      return true;
    }
  })
  await idb_set(key, [item, ...newList]);
  releaseLock(key, releaseKey);
  return;
}