import { message } from 'antd';
import { useEffect, useState } from 'react';
import { generateShortUrl, getShortUrlHistory, getUrlByShortUrl, removeShortUrlHistory } from './api/shorturl';
import { copyTextToClipboard } from './utils';
import HomePage from './page/HomePage';
import 'antd/dist/antd.min.css';
import './App.css';

const PATTERN = "((http|https)://)(www.)?"
  + "[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]"
  + "{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"

function App() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pathname = window.location.pathname;
        if (pathname && pathname.length > 1 && pathname !== '/') {
          const url = await getUrlByShortUrl(pathname.substring(1));
          if (url) {
            window.location.href = url;
          } else {
            message.error("Short URL not found!");
            setInitialized(true);
          }
        } else {
          setInitialized(true);
        }
        const list = await getShortUrlHistory();
        setList(list);
      } catch (e) {
        console.log(e);
        message.error(`${e}`);
      }
    }
    fetchData();
  },[])

  const onGenerate = async (url) => {
    try {
      setLoading(true);
      if (url) {
        if (url.startsWith(window.location.origin)) {
          throw new Error("This URL is not supported!");
        }
        if (url.match(new RegExp(PATTERN))) {
          await generateShortUrl(url);
          const list = await getShortUrlHistory();
          console.log("list", list);
          setList(list)
        } else {
          throw new Error("Invalid URL");
        }
      } else {
        throw new Error("Please input a URL");
      }
    } catch (e) {
      console.log(e);
      message.error(`${e}`);
    }
    setLoading(false);
  }

  const onRemove = async (item) => {
    console.log("onRemove", item);
    await removeShortUrlHistory(item);
    const list = await getShortUrlHistory();
    setList(list);
  }

  const onCopy = async (item) => {
    try {
      await copyTextToClipboard(window.location.origin+'/'+item.short);
      message.info(`Copied`);
    } catch (e) {
      console.log(e);
      message.error(`${e}`);
    }
  }

  return <HomePage initialized={initialized} list={list} loading={loading} 
    onGenerate={onGenerate} onRemove={onRemove} onCopy={onCopy}/>;
}

export default App;
