import { openDB } from 'idb';

const getKeyStore = (key) => {
  let store = "cache";
  return store;
}

const dbPromise = openDB('cache-store', 1, {
  upgrade(db) {
    db.createObjectStore('cache');
  },
});

export async function idb_get(key) {
  const store = getKeyStore(key);
  console.log("idb_get", key, store);
  if (store) {
    return (await dbPromise).get(store, key);
  } else {
    return null;
  }
};
export async function idb_set(key, val) {
  const store = getKeyStore(key);
  if (store) {
    return (await dbPromise).put(store, val, key);
  } else {
    return null;
  }
};

export async function idb_clear(store) {
  return (await dbPromise).clear(store);
};