import { Col, Divider, Input, List, Row, Button } from 'antd';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoCopyOutline } from "react-icons/io5";
import 'antd/dist/antd.min.css';
import './HomePage.css';
import logo from '../../assets/logo.svg';

function HomePage({list, loading, initialized, onGenerate, onRemove, onCopy}) {
  if (!initialized) {
    return null;
  } else {
    return (
      <div className="HomePage">
        <header className="HomePage-header">
          <img src={logo} className="HomePage-logo" alt="logo" />
          <Row>
            <Col span={4}></Col>
            <Col span={16}>
              <Input.Search className='shorten-url' enterButton={"SHORTEN"} onSearch={onGenerate} loading={loading} />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row>
            <Col span={4}></Col>
            <Col span={16}>
              <Divider />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row>
            <Col span={4}></Col>
            <Col span={16}>
              <List
                className='shourt-url-history'
                bordered
                dataSource={list}
                renderItem={item => (
                  <List.Item
                  actions={[
                    <Button type='ghost' onClick={()=> {
                      if (onCopy) onCopy(item)
                    }} icon={<IoCopyOutline/>}></Button>,
                    <Button type='ghost' onClick={()=> {
                      if (onRemove) onRemove(item)
                    }} icon={<IoIosCloseCircleOutline/>}></Button>,
                    ]}>
                    <div className='url'>URL: {item.url}</div>
                    <div className='shorturl'><a href={window.location.origin+'/'+item.short}>{window.location.origin}/{item.short}</a></div>
                  </List.Item>
                )}
              />
            </Col>
            <Col span={4}>
            </Col>
          </Row>
        </header>
      </div>
    );
  }
}

export default HomePage;
