export const delay = (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, time)
  })
}

export const uid = () => {
  // I generate the UID from two parts here 
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  const newUid = firstPart + secondPart;
  return newUid;
}

var ACQUIRED_LOCKS = {};
export const acquireLock = async (key, timeout) => {
  const lock = ACQUIRED_LOCKS[key];
  if (lock) {
    do {
      await delay(timeout);
    } while (ACQUIRED_LOCKS[key]);
    return await acquireLock(key, timeout);
  } else {
    const releaseKey = uid();
    ACQUIRED_LOCKS[key] = releaseKey;
    delay(timeout).then(() => {
      releaseLock(key, releaseKey);
    })
    return ACQUIRED_LOCKS[key];
  }
}

export const releaseLock = (key, releaseKey) => {
  if (ACQUIRED_LOCKS[key] === releaseKey) {
    ACQUIRED_LOCKS[key] = null;
    //console.info("release lock "+key+"->"+ACQUIRED_LOCKS[key]+" by ", releaseKey);
    return true;
  } else if (ACQUIRED_LOCKS[key]) {
    console.error("release lock failed "+key+"->"+ACQUIRED_LOCKS[key]+" by ", releaseKey);
    return false;
  } else {
    return false;
  }
}

export const isEmpty = (value) => {
  if (value) {
    return isEmptyString(value);
  } else {
    return true;
  }
}

export const isEmptyString = (str) => {
  if (typeof str == 'string') {
    if (!str || !str.replace(/\s/g, '').length) {
      return true;
    }
  }
  return false;
}